import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useForm } from "react-hook-form"
import { useGlobalContext } from "../../context/context"
import { DOMAIN_REGEX, DOMAIN_SPACES_REGEX } from "../../REGEX/REGEX"
import { axiosGetRequest } from "../../API/API"
import "../../styles/components/block--login-form.scss"
import "../../styles/components/form--global.scss"
import "../../styles/components/form--login.scss"
import InputOverlay from "../item/inputOverlay"

const SIDELINE_URL = process.env.GATSBY_SIDELINE_URL

const FormLogin = () => {
  const { setIsLoading } = useGlobalContext()
  const [domainExists, setDomainExsists] = useState(true)
  const [placeholderValue, setPlaceholderValue] = useState("your-domain")
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      domain: "",
    },
  })

  const domainValue = watch("domain")

  useEffect(() => {
    if (domainValue) {
      setDomainExsists(true)
    }

    if (
      domainValue.includes(placeholderValue) &&
      domainValue.length > placeholderValue.length
    ) {
      const inputValue = domainValue.substring(placeholderValue.length)
      setValue("domain", inputValue)
    }
  }, [domainValue])

  const formSubmit = (data, _) => {
    setIsLoading(true)
    axiosGetRequest("getDomain", data.domain)
      .then(res => {
        window.location.href = `https://${res.data.domain}.${SIDELINE_URL}`
      })
      .catch(error => {
        console.error(error)
        setDomainExsists(false)
        setIsLoading(false)
      })
      .finally(() => {
        reset()
      })
  }

  return (
    <form
      action={"/"}
      className="form form--global form--login"
      onSubmit={handleSubmit(formSubmit)}
    >
      <div className="form-item form-item-type--domain form-item--domain">
        <label htmlFor="input-full-domain" className="required">
          Domain
        </label>

        <InputOverlay
          placeholderValue={placeholderValue}
          domainValue={domainValue}
        />

        <input
          type="text"
          id="input-full-domain"
          autoComplete="off"
          {...register("domain", {
            required: "Domain is required",
            minLength: {
              value: 3,
              message: "Domain must be at least 3 characters long",
            },
            pattern: {
              value: DOMAIN_REGEX,
              message: `Domain can only include lowercase letters, numbers and "-" character`,
            },
            validate: value => {
              return (
                [DOMAIN_SPACES_REGEX].every(pattern => pattern.test(value)) ||
                "Domain can not include spaces"
              )
            },
          })}
          onKeyUp={() => {
            trigger("domain")
          }}
        />

        {errors.domain || !domainExists ? (
          <small className="text--error">
            {errors.domain
              ? errors.domain.message
              : !domainExists
              ? "Domain does not exist, enter the valid one"
              : null}
          </small>
        ) : null}
      </div>

      <div className="form-actions">
        <button type="submit" className="button button--submit">
          Log In
        </button>

        <div className="description">
          You don't have an account?
          <Link to="/sign-up" className="text text--red">
            Register
          </Link>
        </div>
      </div>
    </form>
  )
}

export default FormLogin
