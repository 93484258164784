import React from "react"
import Container from "../components/block/container"
import FormLogin from "../components/full/formLogin"
import Layout from "../components/layout"
import Loader from "../components/item/loader"
import FieldText from "../components/field/fieldText"
import Seo from "../components/seo"
import { useGlobalContext } from "../context/context"
import "../styles/components/page--form.scss"
import "../styles/components/block--form.scss"
import "../styles/components/block--login-form.scss"

const LoginPage = () => {
  const { isLoading } = useGlobalContext()
  return (
    <>
      <Layout showFooter={false}>
        <Seo
          title="Log in"
          description="Log into your Sideline application account."
          pageClass="page--form"
        />
        <Container
          classes="block--form block--login-form"
          title="Enter your domain name and log into your Sideline application account."
        >
          {isLoading && (
            <Loader
              loaderMsg="Redirecting to Sideline app..."
              classes={"block-loader--form"}
            />
          )}
          <FormLogin />
        </Container>

        <Container classes="block--disclaimer-form">
          <FieldText>
            <p>&copy; Sideline. All Rights Reserved.</p>
          </FieldText>
        </Container>
      </Layout>
    </>
  )
}

export default LoginPage
