import React from "react"
import "../../styles/components/form--input-overlay.scss"

const SIDELINE_URL = `.${process.env.GATSBY_SIDELINE_URL}`

const InputOverlay = ({ domainValue, placeholderValue }) => {
  console.log(domainValue)
  return (
    <div
      className={`form--input-overlay ${
        domainValue ? "hide--overlay-text" : ""
      }`}
      data-suffix={SIDELINE_URL}
    >
      {domainValue ? domainValue : placeholderValue}
    </div>
  )
}

export default InputOverlay
